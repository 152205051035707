export const TALLY_SHEET_CODE_CE_201 = "CE-201";
export const TALLY_SHEET_CODE_CE_201_PV = "CE-201-PV";
export const TALLY_SHEET_CODE_PCE_31 = "PCE-31";
export const TALLY_SHEET_CODE_PCE_34 = "PCE-34";
export const TALLY_SHEET_CODE_PCE_35 = "PCE-35";
export const TALLY_SHEET_CODE_PCE_CE_CO_PR_1 = "PCE-CE-RO-PR-1";
export const TALLY_SHEET_CODE_PCE_CE_CO_PR_2 = "PCE-CE-RO-PR-2";
export const TALLY_SHEET_CODE_PCE_CE_CO_PR_3 = "PCE-CE-RO-PR-3";
export const TALLY_SHEET_CODE_PCE_CE_CO_PR_4 = "PCE-CE-RO-PR-4";
export const TALLY_SHEET_CODE_PCE_CE_RO_V1 = "PCE-CE-RO-V1";
export const TALLY_SHEET_CODE_PCE_R1 = "PCE-R1";
export const TALLY_SHEET_CODE_PCE_R1_PV = "PCE-R1-PV";
export const TALLY_SHEET_CODE_PCE_CE_RO_V2 = "PCE-CE-RO-V2";
export const TALLY_SHEET_CODE_PCE_R2 = "PCE-R2";
export const TALLY_SHEET_CODE_PCE_CE_RO_PR_1 = "PCE-CE-RO-PR-1";
export const TALLY_SHEET_CODE_PCE_CE_RO_PR_2 = "PCE-CE-RO-PR-2";
export const TALLY_SHEET_CODE_PCE_CE_RO_PR_3 = "PCE-CE-RO-PR-3";
export const TALLY_SHEET_CODE_PCE_42 = "PCE-42";
export const TALLY_SHEET_CODE_PCE_PD_V = "PCE-PD-V";
export const TALLY_SHEET_CODE_PCE_PC_V = "PCE-PC-V";
export const TALLY_SHEET_CODE_PCE_PC_CD = "PCE-PC-CD";
export const TALLY_SHEET_CODE_PCE_PC_BS_1 = "PCE-PC-BS-1";
export const TALLY_SHEET_CODE_PCE_PC_BS_2 = "PCE-PC-BS-2";
export const TALLY_SHEET_CODE_PCE_PC_SA_1 = "PCE-PC-SA-1";
export const TALLY_SHEET_CODE_PCE_PC_SA_2 = "PCE-PC-SA-2";
export const TALLY_SHEET_CODE_PCE_POST_PC = "PCE-POST-PC";
