export const TALLY_SHEET_CODE_PRE_34_CO = "PRE-34-CO";
export const TALLY_SHEET_CODE_PRE_34 = "PRE-34";
export const TALLY_SHEET_CODE_PRE_34_I_RO = "PRE-34-I-RO";
export const TALLY_SHEET_CODE_PRE_34_II_RO = "PRE-34-II-RO";
export const TALLY_SHEET_CODE_PRE_34_PD = "PRE-34-PD";
export const TALLY_SHEET_CODE_PRE_34_ED = "PRE-34-ED";
export const TALLY_SHEET_CODE_PRE_34_AI = "PRE-34-AI";
export const TALLY_SHEET_CODE_PRE_41 = "PRE-41";
export const TALLY_SHEET_CODE_CE_201 = "CE-201";
export const TALLY_SHEET_CODE_CE_201_PV = "CE-201-PV";
export const TALLY_SHEET_CODE_PRE_30_PD = "PRE-30-PD";
export const TALLY_SHEET_CODE_PRE_30_ED = "PRE-30-ED";
export const TALLY_SHEET_CODE_PRE_ALL_ISLAND_RESULTS_BY_ELECTORAL_DISTRICTS = "PRE-ALL-ISLAND-RESULTS-BY-ELECTORAL-DISTRICTS";
export const TALLY_SHEET_CODE_PRE_ALL_ISLAND_RESULTS = "PRE-ALL-ISLAND-RESULTS";
