export const WORKFLOW_ACTION_TYPE_VIEW = "VIEW";
export const WORKFLOW_ACTION_TYPE_SAVE = "SAVE";
export const WORKFLOW_ACTION_TYPE_EDIT = "EDIT";
export const WORKFLOW_ACTION_TYPE_PRINT = "PRINT";
export const WORKFLOW_ACTION_TYPE_PRINT_LETTER = "PRINT_LETTER";
export const WORKFLOW_ACTION_TYPE_CERTIFY = "CERTIFY";
export const WORKFLOW_ACTION_TYPE_SUBMIT = "SUBMIT";
export const WORKFLOW_ACTION_TYPE_REQUEST_CHANGES = "REQUEST_CHANGES";
export const WORKFLOW_ACTION_TYPE_MOVE_TO_CERTIFY = "MOVE_TO_CERTIFY";
export const WORKFLOW_ACTION_TYPE_UPLOAD_PROOF_DOCUMENT = "UPLOAD_PROOF_DOCUMENT";
export const WORKFLOW_ACTION_TYPE_RELEASE = "RELEASE";
export const WORKFLOW_ACTION_TYPE_NOTIFY = "NOTIFY";
export const WORKFLOW_ACTION_TYPE_VERIFY = "VERIFY";
export const WORKFLOW_ACTION_TYPE_BACK_TO_VERIFIED = "BACK_TO_VERIFIED";
