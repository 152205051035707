// CE-201
export const TALLY_SHEET_ROW_TYPE_BALLOT_BOX = "BALLOT_BOX";
export const TALLY_SHEET_ROW_TYPE_NUMBER_OF_BALLOTS_RECEIVED = "NUMBER_OF_BALLOTS_RECEIVED";
export const TALLY_SHEET_ROW_TYPE_NUMBER_OF_BALLOTS_SPOILT = "NUMBER_OF_BALLOTS_SPOILT";
export const TALLY_SHEET_ROW_TYPE_NUMBER_OF_BALLOTS_ISSUED = "NUMBER_OF_BALLOTS_ISSUED";
export const TALLY_SHEET_ROW_TYPE_NUMBER_OF_BALLOTS_UNUSED = "NUMBER_OF_BALLOTS_UNUSED";
export const TALLY_SHEET_ROW_TYPE_NUMBER_OF_ORDINARY_BALLOTS_IN_BALLOT_PAPER_ACCOUNT = "NUMBER_OF_ORDINARY_BALLOTS_IN_BALLOT_PAPER_ACCOUNT";
export const TALLY_SHEET_ROW_TYPE_NUMBER_OF_ORDINARY_BALLOTS_IN_BALLOT_BOX = "NUMBER_OF_ORDINARY_BALLOTS_IN_BALLOT_BOX";
export const TALLY_SHEET_ROW_TYPE_NUMBER_OF_TENDERED_BALLOTS_IN_BALLOT_PAPER_ACCOUNT = "NUMBER_OF_TENDERED_BALLOTS_IN_BALLOT_PAPER_ACCOUNT";
export const TALLY_SHEET_ROW_TYPE_NUMBER_OF_TENDERED_BALLOTS_IN_BALLOT_BOX = "NUMBER_OF_TENDERED_BALLOTS_IN_BALLOT_BOX";

// CE-201-PV
export const TALLY_SHEET_ROW_TYPE_SITUATION = "SITUATION";
export const TALLY_SHEET_ROW_TYPE_TIME_OF_COMMENCEMENT = "TIME_OF_COMMENCEMENT";
export const TALLY_SHEET_ROW_TYPE_NUMBER_OF_PACKETS_INSERTED_TO_BALLOT_BOX = "NUMBER_OF_PACKETS_INSERTED_TO_BALLOT_BOX";
export const TALLY_SHEET_ROW_TYPE_NUMBER_OF_PACKETS_FOUND_INSIDE_BALLOT_BOX = "NUMBER_OF_PACKETS_FOUND_INSIDE_BALLOT_BOX";
export const TALLY_SHEET_ROW_TYPE_NUMBER_OF_PACKETS_REJECTED_AFTER_OPENING_COVER_A = "NUMBER_OF_PACKETS_REJECTED_AFTER_OPENING_COVER_A";
export const TALLY_SHEET_ROW_TYPE_NUMBER_OF_PACKETS_REJECTED_AFTER_OPENING_COVER_B = "NUMBER_OF_PACKETS_REJECTED_AFTER_OPENING_COVER_B";
